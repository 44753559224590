import { default as indexutcnovDtlEMeta } from "/vercel/path0/pages/about/index.vue?macro=true";
import { default as authI5JGIXywJyMeta } from "/vercel/path0/pages/auth.vue?macro=true";
import { default as indexA0X6jXVry0Meta } from "/vercel/path0/pages/campaigns/index.vue?macro=true";
import { default as indexfbYDvzVdylMeta } from "/vercel/path0/pages/campaigns/ocean-voyages-institute/index.vue?macro=true";
import { default as indexNHw3sEnudeMeta } from "/vercel/path0/pages/campaigns/plastic-fischer/index.vue?macro=true";
import { default as indexIXpWUjiplsMeta } from "/vercel/path0/pages/campaigns/trees-for-the-future/index.vue?macro=true";
import { default as indexKWFGJjg6P8Meta } from "/vercel/path0/pages/campaigns/yellow-rooms/index.vue?macro=true";
import { default as faqM4LN7jxnMuMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as FAQPy3DgJPQdZMeta } from "/vercel/path0/pages/for-business/FAQ.vue?macro=true";
import { default as HeroM5c85Z1AziMeta } from "/vercel/path0/pages/for-business/Hero.vue?macro=true";
import { default as indexBcBlGXi1vBMeta } from "/vercel/path0/pages/for-business/index.vue?macro=true";
import { default as PartnerskzDn39i33bMeta } from "/vercel/path0/pages/for-business/Partners.vue?macro=true";
import { default as PricingGzPa6CiDCIMeta } from "/vercel/path0/pages/for-business/Pricing.vue?macro=true";
import { default as ReviewsO7qNq50cgPMeta } from "/vercel/path0/pages/for-business/Reviews.vue?macro=true";
import { default as Step1OpLjigrky0Meta } from "/vercel/path0/pages/for-business/Step1.vue?macro=true";
import { default as Step2H4Bvs4u0YNMeta } from "/vercel/path0/pages/for-business/Step2.vue?macro=true";
import { default as Step36NVJsqyFhUMeta } from "/vercel/path0/pages/for-business/Step3.vue?macro=true";
import { default as Step43BJX1T6PLiMeta } from "/vercel/path0/pages/for-business/Step4.vue?macro=true";
import { default as TreeCounterdiWhrW2Qx3Meta } from "/vercel/path0/pages/for-business/TreeCounter.vue?macro=true";
import { default as VettedByBcU889SdlAMeta } from "/vercel/path0/pages/for-business/VettedBy.vue?macro=true";
import { default as forgot_45password9RntqnVzd3Meta } from "/vercel/path0/pages/forgot-password.vue?macro=true";
import { default as CardRmSc6azTSjMeta } from "/vercel/path0/pages/how-it-works/Card.vue?macro=true";
import { default as CarouselCardpGBecdTxXqMeta } from "/vercel/path0/pages/how-it-works/CarouselCard.vue?macro=true";
import { default as FutureShiftutONnAWwmSMeta } from "/vercel/path0/pages/how-it-works/FutureShift.vue?macro=true";
import { default as GlobalImpact4ZjJplTrqtMeta } from "/vercel/path0/pages/how-it-works/GlobalImpact.vue?macro=true";
import { default as HeroSectionNM3MLVwoRnMeta } from "/vercel/path0/pages/how-it-works/HeroSection.vue?macro=true";
import { default as ImpactStoriesXdBcXp4uPYMeta } from "/vercel/path0/pages/how-it-works/ImpactStories.vue?macro=true";
import { default as indexKJG9FeijuMMeta } from "/vercel/path0/pages/how-it-works/index.vue?macro=true";
import { default as InspireGiveWinE4rduS71X7Meta } from "/vercel/path0/pages/how-it-works/InspireGiveWin.vue?macro=true";
import { default as MicroChangeqaGZhITxCIMeta } from "/vercel/path0/pages/how-it-works/MicroChange.vue?macro=true";
import { default as ScalingHopemhtXwqjB6FMeta } from "/vercel/path0/pages/how-it-works/ScalingHope.vue?macro=true";
import { default as TrackImpactCA9Z1eX1IBMeta } from "/vercel/path0/pages/how-it-works/TrackImpact.vue?macro=true";
import { default as CallToActionE4lHpi1UMMMeta } from "/vercel/path0/pages/humanitys-checklist/CallToAction.vue?macro=true";
import { default as ChecklistQX7wqN02VXMeta } from "/vercel/path0/pages/humanitys-checklist/Checklist.vue?macro=true";
import { default as DisplayTitleOIJhdtCFLaMeta } from "/vercel/path0/pages/humanitys-checklist/DisplayTitle.vue?macro=true";
import { default as GoalsWmV6FPamLrMeta } from "/vercel/path0/pages/humanitys-checklist/Goals.vue?macro=true";
import { default as indexlMSCDMUxgAMeta } from "/vercel/path0/pages/humanitys-checklist/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as joingEIiajiiaGMeta } from "/vercel/path0/pages/join.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as logoutBqDDZXgXsyMeta } from "/vercel/path0/pages/logout.vue?macro=true";
import { default as _91id_93r9ZIQ8RBAmMeta } from "/vercel/path0/pages/members/[id].vue?macro=true";
import { default as _91slug_931mcmKfaCzLMeta } from "/vercel/path0/pages/nfc-demo/[slug].vue?macro=true";
import { default as _91type_93wIl9riscKsMeta } from "/vercel/path0/pages/nfc-shirt/[type].vue?macro=true";
import { default as index3aMPc8BDyNMeta } from "/vercel/path0/pages/nfc-shirt/index.vue?macro=true";
import { default as indexFeaijFjPFtMeta } from "/vercel/path0/pages/nfc/index.vue?macro=true";
import { default as Login1D4KWsxUUaMeta } from "/vercel/path0/pages/nfc/Login.vue?macro=true";
import { default as SignupO7wqAVnMZkMeta } from "/vercel/path0/pages/nfc/Signup.vue?macro=true";
import { default as _91slug_930Uagv5clKeMeta } from "/vercel/path0/pages/partners/[slug].vue?macro=true";
import { default as password_45recoveryRwrq5Mg7ZIMeta } from "/vercel/path0/pages/password-recovery.vue?macro=true";
import { default as indexSsrwCXeuTQMeta } from "/vercel/path0/pages/profile/index.vue?macro=true";
import { default as sign_45upHNIilXjClyMeta } from "/vercel/path0/pages/sign-up.vue?macro=true";
import { default as indexo2iav7g2TiMeta } from "/vercel/path0/pages/theory-of-giving/index.vue?macro=true";
import { default as SectionEmuMqnSdHEMeta } from "/vercel/path0/pages/theory-of-giving/Section.vue?macro=true";
import { default as indexWP7qSgvA03Meta } from "/vercel/path0/pages/who-we-are/index.vue?macro=true";
import { default as UserCardd8LjlfZbJfMeta } from "/vercel/path0/pages/who-we-are/UserCard.vue?macro=true";
export default [
  {
    name: indexutcnovDtlEMeta?.name ?? "about",
    path: indexutcnovDtlEMeta?.path ?? "/about",
    meta: indexutcnovDtlEMeta || {},
    alias: indexutcnovDtlEMeta?.alias || [],
    redirect: indexutcnovDtlEMeta?.redirect,
    component: () => import("/vercel/path0/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: authI5JGIXywJyMeta?.name ?? "auth",
    path: authI5JGIXywJyMeta?.path ?? "/auth",
    meta: authI5JGIXywJyMeta || {},
    alias: authI5JGIXywJyMeta?.alias || [],
    redirect: authI5JGIXywJyMeta?.redirect,
    component: () => import("/vercel/path0/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: indexA0X6jXVry0Meta?.name ?? "campaigns",
    path: indexA0X6jXVry0Meta?.path ?? "/campaigns",
    meta: indexA0X6jXVry0Meta || {},
    alias: indexA0X6jXVry0Meta?.alias || [],
    redirect: indexA0X6jXVry0Meta?.redirect,
    component: () => import("/vercel/path0/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: indexfbYDvzVdylMeta?.name ?? "campaigns-ocean-voyages-institute",
    path: indexfbYDvzVdylMeta?.path ?? "/campaigns/ocean-voyages-institute",
    meta: indexfbYDvzVdylMeta || {},
    alias: indexfbYDvzVdylMeta?.alias || [],
    redirect: indexfbYDvzVdylMeta?.redirect,
    component: () => import("/vercel/path0/pages/campaigns/ocean-voyages-institute/index.vue").then(m => m.default || m)
  },
  {
    name: indexNHw3sEnudeMeta?.name ?? "campaigns-plastic-fischer",
    path: indexNHw3sEnudeMeta?.path ?? "/campaigns/plastic-fischer",
    meta: indexNHw3sEnudeMeta || {},
    alias: indexNHw3sEnudeMeta?.alias || [],
    redirect: indexNHw3sEnudeMeta?.redirect,
    component: () => import("/vercel/path0/pages/campaigns/plastic-fischer/index.vue").then(m => m.default || m)
  },
  {
    name: indexIXpWUjiplsMeta?.name ?? "campaigns-trees-for-the-future",
    path: indexIXpWUjiplsMeta?.path ?? "/campaigns/trees-for-the-future",
    meta: indexIXpWUjiplsMeta || {},
    alias: indexIXpWUjiplsMeta?.alias || [],
    redirect: indexIXpWUjiplsMeta?.redirect,
    component: () => import("/vercel/path0/pages/campaigns/trees-for-the-future/index.vue").then(m => m.default || m)
  },
  {
    name: indexKWFGJjg6P8Meta?.name ?? "campaigns-yellow-rooms",
    path: indexKWFGJjg6P8Meta?.path ?? "/campaigns/yellow-rooms",
    meta: indexKWFGJjg6P8Meta || {},
    alias: indexKWFGJjg6P8Meta?.alias || [],
    redirect: indexKWFGJjg6P8Meta?.redirect,
    component: () => import("/vercel/path0/pages/campaigns/yellow-rooms/index.vue").then(m => m.default || m)
  },
  {
    name: faqM4LN7jxnMuMeta?.name ?? "faq",
    path: faqM4LN7jxnMuMeta?.path ?? "/faq",
    meta: faqM4LN7jxnMuMeta || {},
    alias: faqM4LN7jxnMuMeta?.alias || [],
    redirect: faqM4LN7jxnMuMeta?.redirect,
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: FAQPy3DgJPQdZMeta?.name ?? "for-business-FAQ",
    path: FAQPy3DgJPQdZMeta?.path ?? "/for-business/FAQ",
    meta: FAQPy3DgJPQdZMeta || {},
    alias: FAQPy3DgJPQdZMeta?.alias || [],
    redirect: FAQPy3DgJPQdZMeta?.redirect,
    component: () => import("/vercel/path0/pages/for-business/FAQ.vue").then(m => m.default || m)
  },
  {
    name: HeroM5c85Z1AziMeta?.name ?? "for-business-Hero",
    path: HeroM5c85Z1AziMeta?.path ?? "/for-business/Hero",
    meta: HeroM5c85Z1AziMeta || {},
    alias: HeroM5c85Z1AziMeta?.alias || [],
    redirect: HeroM5c85Z1AziMeta?.redirect,
    component: () => import("/vercel/path0/pages/for-business/Hero.vue").then(m => m.default || m)
  },
  {
    name: indexBcBlGXi1vBMeta?.name ?? "for-business",
    path: indexBcBlGXi1vBMeta?.path ?? "/for-business",
    meta: indexBcBlGXi1vBMeta || {},
    alias: indexBcBlGXi1vBMeta?.alias || [],
    redirect: indexBcBlGXi1vBMeta?.redirect,
    component: () => import("/vercel/path0/pages/for-business/index.vue").then(m => m.default || m)
  },
  {
    name: PartnerskzDn39i33bMeta?.name ?? "for-business-Partners",
    path: PartnerskzDn39i33bMeta?.path ?? "/for-business/Partners",
    meta: PartnerskzDn39i33bMeta || {},
    alias: PartnerskzDn39i33bMeta?.alias || [],
    redirect: PartnerskzDn39i33bMeta?.redirect,
    component: () => import("/vercel/path0/pages/for-business/Partners.vue").then(m => m.default || m)
  },
  {
    name: PricingGzPa6CiDCIMeta?.name ?? "for-business-Pricing",
    path: PricingGzPa6CiDCIMeta?.path ?? "/for-business/Pricing",
    meta: PricingGzPa6CiDCIMeta || {},
    alias: PricingGzPa6CiDCIMeta?.alias || [],
    redirect: PricingGzPa6CiDCIMeta?.redirect,
    component: () => import("/vercel/path0/pages/for-business/Pricing.vue").then(m => m.default || m)
  },
  {
    name: ReviewsO7qNq50cgPMeta?.name ?? "for-business-Reviews",
    path: ReviewsO7qNq50cgPMeta?.path ?? "/for-business/Reviews",
    meta: ReviewsO7qNq50cgPMeta || {},
    alias: ReviewsO7qNq50cgPMeta?.alias || [],
    redirect: ReviewsO7qNq50cgPMeta?.redirect,
    component: () => import("/vercel/path0/pages/for-business/Reviews.vue").then(m => m.default || m)
  },
  {
    name: Step1OpLjigrky0Meta?.name ?? "for-business-Step1",
    path: Step1OpLjigrky0Meta?.path ?? "/for-business/Step1",
    meta: Step1OpLjigrky0Meta || {},
    alias: Step1OpLjigrky0Meta?.alias || [],
    redirect: Step1OpLjigrky0Meta?.redirect,
    component: () => import("/vercel/path0/pages/for-business/Step1.vue").then(m => m.default || m)
  },
  {
    name: Step2H4Bvs4u0YNMeta?.name ?? "for-business-Step2",
    path: Step2H4Bvs4u0YNMeta?.path ?? "/for-business/Step2",
    meta: Step2H4Bvs4u0YNMeta || {},
    alias: Step2H4Bvs4u0YNMeta?.alias || [],
    redirect: Step2H4Bvs4u0YNMeta?.redirect,
    component: () => import("/vercel/path0/pages/for-business/Step2.vue").then(m => m.default || m)
  },
  {
    name: Step36NVJsqyFhUMeta?.name ?? "for-business-Step3",
    path: Step36NVJsqyFhUMeta?.path ?? "/for-business/Step3",
    meta: Step36NVJsqyFhUMeta || {},
    alias: Step36NVJsqyFhUMeta?.alias || [],
    redirect: Step36NVJsqyFhUMeta?.redirect,
    component: () => import("/vercel/path0/pages/for-business/Step3.vue").then(m => m.default || m)
  },
  {
    name: Step43BJX1T6PLiMeta?.name ?? "for-business-Step4",
    path: Step43BJX1T6PLiMeta?.path ?? "/for-business/Step4",
    meta: Step43BJX1T6PLiMeta || {},
    alias: Step43BJX1T6PLiMeta?.alias || [],
    redirect: Step43BJX1T6PLiMeta?.redirect,
    component: () => import("/vercel/path0/pages/for-business/Step4.vue").then(m => m.default || m)
  },
  {
    name: TreeCounterdiWhrW2Qx3Meta?.name ?? "for-business-TreeCounter",
    path: TreeCounterdiWhrW2Qx3Meta?.path ?? "/for-business/TreeCounter",
    meta: TreeCounterdiWhrW2Qx3Meta || {},
    alias: TreeCounterdiWhrW2Qx3Meta?.alias || [],
    redirect: TreeCounterdiWhrW2Qx3Meta?.redirect,
    component: () => import("/vercel/path0/pages/for-business/TreeCounter.vue").then(m => m.default || m)
  },
  {
    name: VettedByBcU889SdlAMeta?.name ?? "for-business-VettedBy",
    path: VettedByBcU889SdlAMeta?.path ?? "/for-business/VettedBy",
    meta: VettedByBcU889SdlAMeta || {},
    alias: VettedByBcU889SdlAMeta?.alias || [],
    redirect: VettedByBcU889SdlAMeta?.redirect,
    component: () => import("/vercel/path0/pages/for-business/VettedBy.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password9RntqnVzd3Meta?.name ?? "forgot-password",
    path: forgot_45password9RntqnVzd3Meta?.path ?? "/forgot-password",
    meta: forgot_45password9RntqnVzd3Meta || {},
    alias: forgot_45password9RntqnVzd3Meta?.alias || [],
    redirect: forgot_45password9RntqnVzd3Meta?.redirect,
    component: () => import("/vercel/path0/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: CardRmSc6azTSjMeta?.name ?? "how-it-works-Card",
    path: CardRmSc6azTSjMeta?.path ?? "/how-it-works/Card",
    meta: CardRmSc6azTSjMeta || {},
    alias: CardRmSc6azTSjMeta?.alias || [],
    redirect: CardRmSc6azTSjMeta?.redirect,
    component: () => import("/vercel/path0/pages/how-it-works/Card.vue").then(m => m.default || m)
  },
  {
    name: CarouselCardpGBecdTxXqMeta?.name ?? "how-it-works-CarouselCard",
    path: CarouselCardpGBecdTxXqMeta?.path ?? "/how-it-works/CarouselCard",
    meta: CarouselCardpGBecdTxXqMeta || {},
    alias: CarouselCardpGBecdTxXqMeta?.alias || [],
    redirect: CarouselCardpGBecdTxXqMeta?.redirect,
    component: () => import("/vercel/path0/pages/how-it-works/CarouselCard.vue").then(m => m.default || m)
  },
  {
    name: FutureShiftutONnAWwmSMeta?.name ?? "how-it-works-FutureShift",
    path: FutureShiftutONnAWwmSMeta?.path ?? "/how-it-works/FutureShift",
    meta: FutureShiftutONnAWwmSMeta || {},
    alias: FutureShiftutONnAWwmSMeta?.alias || [],
    redirect: FutureShiftutONnAWwmSMeta?.redirect,
    component: () => import("/vercel/path0/pages/how-it-works/FutureShift.vue").then(m => m.default || m)
  },
  {
    name: GlobalImpact4ZjJplTrqtMeta?.name ?? "how-it-works-GlobalImpact",
    path: GlobalImpact4ZjJplTrqtMeta?.path ?? "/how-it-works/GlobalImpact",
    meta: GlobalImpact4ZjJplTrqtMeta || {},
    alias: GlobalImpact4ZjJplTrqtMeta?.alias || [],
    redirect: GlobalImpact4ZjJplTrqtMeta?.redirect,
    component: () => import("/vercel/path0/pages/how-it-works/GlobalImpact.vue").then(m => m.default || m)
  },
  {
    name: HeroSectionNM3MLVwoRnMeta?.name ?? "how-it-works-HeroSection",
    path: HeroSectionNM3MLVwoRnMeta?.path ?? "/how-it-works/HeroSection",
    meta: HeroSectionNM3MLVwoRnMeta || {},
    alias: HeroSectionNM3MLVwoRnMeta?.alias || [],
    redirect: HeroSectionNM3MLVwoRnMeta?.redirect,
    component: () => import("/vercel/path0/pages/how-it-works/HeroSection.vue").then(m => m.default || m)
  },
  {
    name: ImpactStoriesXdBcXp4uPYMeta?.name ?? "how-it-works-ImpactStories",
    path: ImpactStoriesXdBcXp4uPYMeta?.path ?? "/how-it-works/ImpactStories",
    meta: ImpactStoriesXdBcXp4uPYMeta || {},
    alias: ImpactStoriesXdBcXp4uPYMeta?.alias || [],
    redirect: ImpactStoriesXdBcXp4uPYMeta?.redirect,
    component: () => import("/vercel/path0/pages/how-it-works/ImpactStories.vue").then(m => m.default || m)
  },
  {
    name: indexKJG9FeijuMMeta?.name ?? "how-it-works",
    path: indexKJG9FeijuMMeta?.path ?? "/how-it-works",
    meta: indexKJG9FeijuMMeta || {},
    alias: indexKJG9FeijuMMeta?.alias || [],
    redirect: indexKJG9FeijuMMeta?.redirect,
    component: () => import("/vercel/path0/pages/how-it-works/index.vue").then(m => m.default || m)
  },
  {
    name: InspireGiveWinE4rduS71X7Meta?.name ?? "how-it-works-InspireGiveWin",
    path: InspireGiveWinE4rduS71X7Meta?.path ?? "/how-it-works/InspireGiveWin",
    meta: InspireGiveWinE4rduS71X7Meta || {},
    alias: InspireGiveWinE4rduS71X7Meta?.alias || [],
    redirect: InspireGiveWinE4rduS71X7Meta?.redirect,
    component: () => import("/vercel/path0/pages/how-it-works/InspireGiveWin.vue").then(m => m.default || m)
  },
  {
    name: MicroChangeqaGZhITxCIMeta?.name ?? "how-it-works-MicroChange",
    path: MicroChangeqaGZhITxCIMeta?.path ?? "/how-it-works/MicroChange",
    meta: MicroChangeqaGZhITxCIMeta || {},
    alias: MicroChangeqaGZhITxCIMeta?.alias || [],
    redirect: MicroChangeqaGZhITxCIMeta?.redirect,
    component: () => import("/vercel/path0/pages/how-it-works/MicroChange.vue").then(m => m.default || m)
  },
  {
    name: ScalingHopemhtXwqjB6FMeta?.name ?? "how-it-works-ScalingHope",
    path: ScalingHopemhtXwqjB6FMeta?.path ?? "/how-it-works/ScalingHope",
    meta: ScalingHopemhtXwqjB6FMeta || {},
    alias: ScalingHopemhtXwqjB6FMeta?.alias || [],
    redirect: ScalingHopemhtXwqjB6FMeta?.redirect,
    component: () => import("/vercel/path0/pages/how-it-works/ScalingHope.vue").then(m => m.default || m)
  },
  {
    name: TrackImpactCA9Z1eX1IBMeta?.name ?? "how-it-works-TrackImpact",
    path: TrackImpactCA9Z1eX1IBMeta?.path ?? "/how-it-works/TrackImpact",
    meta: TrackImpactCA9Z1eX1IBMeta || {},
    alias: TrackImpactCA9Z1eX1IBMeta?.alias || [],
    redirect: TrackImpactCA9Z1eX1IBMeta?.redirect,
    component: () => import("/vercel/path0/pages/how-it-works/TrackImpact.vue").then(m => m.default || m)
  },
  {
    name: CallToActionE4lHpi1UMMMeta?.name ?? "humanitys-checklist-CallToAction",
    path: CallToActionE4lHpi1UMMMeta?.path ?? "/humanitys-checklist/CallToAction",
    meta: CallToActionE4lHpi1UMMMeta || {},
    alias: CallToActionE4lHpi1UMMMeta?.alias || [],
    redirect: CallToActionE4lHpi1UMMMeta?.redirect,
    component: () => import("/vercel/path0/pages/humanitys-checklist/CallToAction.vue").then(m => m.default || m)
  },
  {
    name: ChecklistQX7wqN02VXMeta?.name ?? "humanitys-checklist-Checklist",
    path: ChecklistQX7wqN02VXMeta?.path ?? "/humanitys-checklist/Checklist",
    meta: ChecklistQX7wqN02VXMeta || {},
    alias: ChecklistQX7wqN02VXMeta?.alias || [],
    redirect: ChecklistQX7wqN02VXMeta?.redirect,
    component: () => import("/vercel/path0/pages/humanitys-checklist/Checklist.vue").then(m => m.default || m)
  },
  {
    name: DisplayTitleOIJhdtCFLaMeta?.name ?? "humanitys-checklist-DisplayTitle",
    path: DisplayTitleOIJhdtCFLaMeta?.path ?? "/humanitys-checklist/DisplayTitle",
    meta: DisplayTitleOIJhdtCFLaMeta || {},
    alias: DisplayTitleOIJhdtCFLaMeta?.alias || [],
    redirect: DisplayTitleOIJhdtCFLaMeta?.redirect,
    component: () => import("/vercel/path0/pages/humanitys-checklist/DisplayTitle.vue").then(m => m.default || m)
  },
  {
    name: GoalsWmV6FPamLrMeta?.name ?? "humanitys-checklist-Goals",
    path: GoalsWmV6FPamLrMeta?.path ?? "/humanitys-checklist/Goals",
    meta: GoalsWmV6FPamLrMeta || {},
    alias: GoalsWmV6FPamLrMeta?.alias || [],
    redirect: GoalsWmV6FPamLrMeta?.redirect,
    component: () => import("/vercel/path0/pages/humanitys-checklist/Goals.vue").then(m => m.default || m)
  },
  {
    name: indexlMSCDMUxgAMeta?.name ?? "humanitys-checklist",
    path: indexlMSCDMUxgAMeta?.path ?? "/humanitys-checklist",
    meta: indexlMSCDMUxgAMeta || {},
    alias: indexlMSCDMUxgAMeta?.alias || [],
    redirect: indexlMSCDMUxgAMeta?.redirect,
    component: () => import("/vercel/path0/pages/humanitys-checklist/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: joingEIiajiiaGMeta?.name ?? "join",
    path: joingEIiajiiaGMeta?.path ?? "/join",
    meta: joingEIiajiiaGMeta || {},
    alias: joingEIiajiiaGMeta?.alias || [],
    redirect: joingEIiajiiaGMeta?.redirect,
    component: () => import("/vercel/path0/pages/join.vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login",
    path: logint0AWlhQgM0Meta?.path ?? "/login",
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutBqDDZXgXsyMeta?.name ?? "logout",
    path: logoutBqDDZXgXsyMeta?.path ?? "/logout",
    meta: logoutBqDDZXgXsyMeta || {},
    alias: logoutBqDDZXgXsyMeta?.alias || [],
    redirect: logoutBqDDZXgXsyMeta?.redirect,
    component: () => import("/vercel/path0/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: _91id_93r9ZIQ8RBAmMeta?.name ?? "members-id",
    path: _91id_93r9ZIQ8RBAmMeta?.path ?? "/members/:id()",
    meta: _91id_93r9ZIQ8RBAmMeta || {},
    alias: _91id_93r9ZIQ8RBAmMeta?.alias || [],
    redirect: _91id_93r9ZIQ8RBAmMeta?.redirect,
    component: () => import("/vercel/path0/pages/members/[id].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931mcmKfaCzLMeta?.name ?? "nfc-demo-slug",
    path: _91slug_931mcmKfaCzLMeta?.path ?? "/nfc-demo/:slug()",
    meta: _91slug_931mcmKfaCzLMeta || {},
    alias: _91slug_931mcmKfaCzLMeta?.alias || [],
    redirect: _91slug_931mcmKfaCzLMeta?.redirect,
    component: () => import("/vercel/path0/pages/nfc-demo/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91type_93wIl9riscKsMeta?.name ?? "nfc-shirt-type",
    path: _91type_93wIl9riscKsMeta?.path ?? "/nfc-shirt/:type()",
    meta: _91type_93wIl9riscKsMeta || {},
    alias: _91type_93wIl9riscKsMeta?.alias || [],
    redirect: _91type_93wIl9riscKsMeta?.redirect,
    component: () => import("/vercel/path0/pages/nfc-shirt/[type].vue").then(m => m.default || m)
  },
  {
    name: index3aMPc8BDyNMeta?.name ?? "nfc-shirt",
    path: index3aMPc8BDyNMeta?.path ?? "/nfc-shirt",
    meta: index3aMPc8BDyNMeta || {},
    alias: index3aMPc8BDyNMeta?.alias || [],
    redirect: index3aMPc8BDyNMeta?.redirect,
    component: () => import("/vercel/path0/pages/nfc-shirt/index.vue").then(m => m.default || m)
  },
  {
    name: indexFeaijFjPFtMeta?.name ?? "nfc",
    path: indexFeaijFjPFtMeta?.path ?? "/nfc",
    meta: indexFeaijFjPFtMeta || {},
    alias: indexFeaijFjPFtMeta?.alias || [],
    redirect: indexFeaijFjPFtMeta?.redirect,
    component: () => import("/vercel/path0/pages/nfc/index.vue").then(m => m.default || m)
  },
  {
    name: Login1D4KWsxUUaMeta?.name ?? "nfc-Login",
    path: Login1D4KWsxUUaMeta?.path ?? "/nfc/Login",
    meta: Login1D4KWsxUUaMeta || {},
    alias: Login1D4KWsxUUaMeta?.alias || [],
    redirect: Login1D4KWsxUUaMeta?.redirect,
    component: () => import("/vercel/path0/pages/nfc/Login.vue").then(m => m.default || m)
  },
  {
    name: SignupO7wqAVnMZkMeta?.name ?? "nfc-Signup",
    path: SignupO7wqAVnMZkMeta?.path ?? "/nfc/Signup",
    meta: SignupO7wqAVnMZkMeta || {},
    alias: SignupO7wqAVnMZkMeta?.alias || [],
    redirect: SignupO7wqAVnMZkMeta?.redirect,
    component: () => import("/vercel/path0/pages/nfc/Signup.vue").then(m => m.default || m)
  },
  {
    name: _91slug_930Uagv5clKeMeta?.name ?? "partners-slug",
    path: _91slug_930Uagv5clKeMeta?.path ?? "/partners/:slug()",
    meta: _91slug_930Uagv5clKeMeta || {},
    alias: _91slug_930Uagv5clKeMeta?.alias || [],
    redirect: _91slug_930Uagv5clKeMeta?.redirect,
    component: () => import("/vercel/path0/pages/partners/[slug].vue").then(m => m.default || m)
  },
  {
    name: password_45recoveryRwrq5Mg7ZIMeta?.name ?? "password-recovery",
    path: password_45recoveryRwrq5Mg7ZIMeta?.path ?? "/password-recovery",
    meta: password_45recoveryRwrq5Mg7ZIMeta || {},
    alias: password_45recoveryRwrq5Mg7ZIMeta?.alias || [],
    redirect: password_45recoveryRwrq5Mg7ZIMeta?.redirect,
    component: () => import("/vercel/path0/pages/password-recovery.vue").then(m => m.default || m)
  },
  {
    name: indexSsrwCXeuTQMeta?.name ?? "profile",
    path: indexSsrwCXeuTQMeta?.path ?? "/profile",
    meta: indexSsrwCXeuTQMeta || {},
    alias: indexSsrwCXeuTQMeta?.alias || [],
    redirect: indexSsrwCXeuTQMeta?.redirect,
    component: () => import("/vercel/path0/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: sign_45upHNIilXjClyMeta?.name ?? "sign-up",
    path: sign_45upHNIilXjClyMeta?.path ?? "/sign-up",
    meta: sign_45upHNIilXjClyMeta || {},
    alias: sign_45upHNIilXjClyMeta?.alias || [],
    redirect: sign_45upHNIilXjClyMeta?.redirect,
    component: () => import("/vercel/path0/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: indexo2iav7g2TiMeta?.name ?? "theory-of-giving",
    path: indexo2iav7g2TiMeta?.path ?? "/theory-of-giving",
    meta: indexo2iav7g2TiMeta || {},
    alias: indexo2iav7g2TiMeta?.alias || [],
    redirect: indexo2iav7g2TiMeta?.redirect,
    component: () => import("/vercel/path0/pages/theory-of-giving/index.vue").then(m => m.default || m)
  },
  {
    name: SectionEmuMqnSdHEMeta?.name ?? "theory-of-giving-Section",
    path: SectionEmuMqnSdHEMeta?.path ?? "/theory-of-giving/Section",
    meta: SectionEmuMqnSdHEMeta || {},
    alias: SectionEmuMqnSdHEMeta?.alias || [],
    redirect: SectionEmuMqnSdHEMeta?.redirect,
    component: () => import("/vercel/path0/pages/theory-of-giving/Section.vue").then(m => m.default || m)
  },
  {
    name: indexWP7qSgvA03Meta?.name ?? "who-we-are",
    path: indexWP7qSgvA03Meta?.path ?? "/who-we-are",
    meta: indexWP7qSgvA03Meta || {},
    alias: indexWP7qSgvA03Meta?.alias || [],
    redirect: indexWP7qSgvA03Meta?.redirect,
    component: () => import("/vercel/path0/pages/who-we-are/index.vue").then(m => m.default || m)
  },
  {
    name: UserCardd8LjlfZbJfMeta?.name ?? "who-we-are-UserCard",
    path: UserCardd8LjlfZbJfMeta?.path ?? "/who-we-are/UserCard",
    meta: UserCardd8LjlfZbJfMeta || {},
    alias: UserCardd8LjlfZbJfMeta?.alias || [],
    redirect: UserCardd8LjlfZbJfMeta?.redirect,
    component: () => import("/vercel/path0/pages/who-we-are/UserCard.vue").then(m => m.default || m)
  }
]